.recipientContainer {
    margin: 8px 24px;
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
}

.composeFooter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row-reverse;
}

.assignmentContainer {
    display: flex;
    gap: 10px;
    margin: 5px 10px 0 0;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.assignmentSelect {
    width: 240px;
}

.divider {
    width: 102%;
    margin-top: 15px;
}
.footerContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.attachmentsContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

a {
    text-decoration: none;
}

.fileChip {
    cursor: pointer;
}

.fieldsContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}

.headerContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    padding: 0 16px;
    width: 100%;
}
